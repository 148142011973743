
import { defineComponent } from 'vue';

import Card from 'primevue/card';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default defineComponent({
  name: 'MobilePolicy',
  components: {
    Card,
    Accordion,
    AccordionTab,
  },
});
